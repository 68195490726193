import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import Home from './main';
import Vote from './vote';

function App() {
    useEffect(() => {
        // 設定標籤名稱
        document.title = "Pizza&KFC";
    
        // 可選：清除效果，在組件卸載時重置標籤名稱
        return () => {
            document.title = "原來的標籤名稱";
        };
      }, []);
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    {/* Add header content here */}
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/vote" element={<Vote />} />
                    </Routes>
                </main>
                <div className='footer'>
                    <div className='footer_block'>
                        <span>
                        展示生活中最能體現“Proud to be Me!”精神的時刻！無論是在工作、學習還是生活中的精彩瞬間，我們都希望看到你/妳最驕傲的一面。歡迎你/妳展示自信和獨特，讓具有彩虹精神的故事激勵更多人！
                        </span>
                        <div className='line'></div>
                        <span>
                        聯絡電話 ｜ 02-2515-6818 <p></p>
                        籌辦單位 ｜ 台灣怡和餐飲集團Rainbow Month「Proud to be Me」籌備小組
                        </span>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
