// components/ActivityInfo.js
import React from 'react';

const ActivityInfo = () => {
    return (
        <div className='info'>
        <div className='info_block'>
            <span className='t24'>活動主題</span>
            <span className='t18'>
            展示您生活中認為能夠體現”Proud to be Me!”的精神，展現自己最驕傲的時刻（並包含彩虹的元素），分享給我們，作品內容不限（可為裝扮、各種場合…等），但必須包含人像，可以使用任何設備或風格進行拍攝。
            </span>
        </div>
        <div className='info_block'>
            <span className='t24'>活動期間與參賽資格</span>
            <span className='t18'>
            第一階段照片上傳期間：2024年6月17日-2024年9月15日 23:59 截止。<p></p>
            第二階段照片投票期間：2024年9月23日~2024年10月11日 23:59 截止。<p></p>
            參賽與投票資格：Store/RSC PT/FT：2024/05/31(含)前到職(如下參賽規範1.)。
            </span>
        </div>
        <div className='info_block'>
            <span className='t24'>投稿方式與投票方式</span>
            <ul className='t18'>
            <li>請至Proud to be Me 照片競賽活動網頁進行投稿，每人至多提交1張照片作品。</li>
            <li>請詳讀參賽規範，作品上傳時將視為全數同意活動規則。 </li>
            <li>投稿時，請點選網頁下方「上傳照片」並登入(帳號密碼皆為員編)上傳照片，確認上傳照片後無法再次登入修改。</li>
            <li>第二階段投票開放時，具備投票資格者，請於網頁下方點選「立即登入」→「參與投票」登進行投票，每張照片限投票一次並採取一次性投票且經確認後即無法修改及再次登入。</li>
            <li>依所屬品牌進行分開投票，即Pizza Hut員工只能選投Pizza Hut入選名單，KFC亦同。(如下參賽規範12.)</li>
            </ul>
        </div>
        <div className='info_block'>
            <span className='t24'>作品規範</span>
            <ul className='t18'>
            <li>必須為原創作品，禁止盜用、翻拍、複製等修改，但可進行亮度調整與適當裁切。</li>
            <li>禁止不雅、有害社會風俗或違法之內容。</li>
            <li>上傳照片格式須為圖檔(.jpg/.jpeg/.png/Heic/Heif)。</li>
            <li>投稿圖片大小限制在2MB以下，建議解析度高於800KB以上。</li>
            </ul>
        </div>
        <div className='info_block'>
            <span className='t24'>評審規則</span>
            <span className='t18'>〈Proud to be Me! 照片競賽〉活動評審委員會由2024 JRG Rainbow Month Taskforce team 組成，評選過程包含第一階段照片初選與第二階段分數計分，階段進行初步篩選：</span>

        </div>
        <div className='info_block'>
            <span className='t24'>第一階段-初選</span>
            <ul className='t18'>
                <li>
                若無「Proud to be Me!」的主題，或作品違反作品規範者，視為不符參賽資格，將無法進入第二階段(違反相關規定者不另行通知)。
                </li>
                <li>遴選標準：主題契合度 40%，畫面呈現 30%，創意呈現 30%。</li>
                <li>Pizza Hut & KFC 各取分數前50名進入第二階段的投票決選。</li>
            </ul>

        </div>
        <div className='info_block'>
            <span className='t24'>第二階段：投票規則</span>
            <ul className='t18'>
                <li>計分方式：投票票數 50%，參與系列活動 30%，評審票數 20%。</li>
                <li>計分說明：</li> 
                <div className='t18 ul2'>
                    <li>(1)投票票數 (50%)：雙品牌各自計分，取最終投票票數排名前十者，分數如下：</li>
                    <ul>
                        <li>第一名：50分。</li>
                        <li>第二名：45分。</li>
                        <li>第三名：40分。</li>
                        <li>第四名：35分。</li>
                        <li>第五名：30分。</li>
                        <li>第六至第十名：25分。</li>
                    </ul>
                    <li>(2)參與其他活動：</li>
                    <ul>
                        <li>門市夥伴：職護活動(5分)、對談沙龍線上參加並簽到(25分)。</li>
                        <li>RSC同仁：職護活動(4分)、對談沙龍(限實體，13分)、JRG電影院 (限實體，13分)。</li>
                    </ul>
                    <li>(3)評審委員(20%)：各委員各兩票，委員票數1票占比為10%。</li>
                </div>
            </ul>
            

        </div>
        <div className='info_block'>
            <span className='t24'>獎項</span>
            <ul className='t18'>
            <ol>
                <li>以組(或個人)為單位，各品牌各取總計分前十名(視徵件狀態調整獎項名額)：<p></p>
   金獎：現金 3,000元。<p></p>
   銀獎：現金 2,000元。<p></p>
   銅獎：現金 1,000元。<p></p>
   第四名：現金 800元。<p></p>
   第五名：現金 600元。<p></p>
   彩虹獎 (第六至第十名)：禮遇卡Pizza Hut & KFC 各300元，共計600元。
</li>
                <li>得獎名單將於2024年10月25日公布，頒獎將另行進行。   
                </li>
                <li>得獎者須於2024年11月30日(前)仍在職，若已離職者，該獎項不補發並以從缺計算。   
                </li>
                <li>得獎者為總公司同仁，將直接由評審委員頒發獎項，若為門市夥伴，則於各品牌 AC meeting上頒發，並由得獎者所屬門市之對應AC代為簽收領獎。   
                </li>
            </ol>
            </ul>
        </div>
        </div>
    );
}

export default ActivityInfo;
