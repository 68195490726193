// components/ParticipationRules.js
import React from 'react';

const ParticipationRules = () => {
    return (
        <div className='info bottom '>
        <ul className='t18'>
            <li>限台灣富利餐飲股份有限公司員工，參賽者與投票資格皆限定於2024年05月31日前(含當日)的入職員工(不包含留職停薪)，如為外籍自然人身分，需具有效期超過 6 個月以上之中華民國新式外來人口統一證號。</li>
            <li>參賽者照片同時有非員工合照時，該參賽者與非員工視為同組參賽者，應共同遵守本參賽規範。參賽者應擔保取得該非員工對所有參賽規範之同意，且參賽照片為合法之攝影作品，未侵犯任何第三人之智慧財產權還其他權利與利益，且未有任何第三人對該參賽照片具備任何權利，如有違反，相關法律責任應由參賽者自行負責。</li>
            <li>凡報名參加之參賽者者(含非員工)，即視同本活動全部規定事項，並且授予Jardine Restaurant Group怡和餐飲集團與富利餐飲股份有限公司所有(下稱JRG)永久、不可撤銷、全球適用且可再授權之著作財產權，且不對JRG主張著作人格權侵害。</li>
            <li>JRG依著作權得以包括但不限於展覽、宣傳、公開展示、口述、不限時間與次數．．．等方式使用。</li>
            <li>參賽人員上傳照片時，視為已閱讀並同意活動全部事項，如有違反本活動參賽規範者，經查證屬實，籌辦小組得逕行取消該參賽者參賽或得獎資格，並追回已領取之獎項，若有涉著作權侵害之法律責任，由參賽者自行負責，概與JRG無關，並賠償JRG所受一切損害。</li>
            <li>參賽作品須為原創作品，並按照活動主旨參賽，禁止使用他人作品(包括但不限於網路取得、翻拍…)，且不得含有任何具菸酒、藥品、猥褻、挑釁、毀謗、情色、暴力、種族歧視、違反法令、其他任何引人厭惡或不合宜之內容或其他影響JRG商譽之內容。</li>
            <li>上傳之參賽作品須保留完整、真實之中繼資料(EXIF)，若刻意修改、抹去、偽造中繼資料，屬涉犯偽造私文書罪（刑法第210條），處五年以下有期徒刑。</li>
            <li>參賽作品須符合該次活動攝影主題、作品規格。</li>
            <li>參賽者不可同時出現於其他參賽者的作品中，若違反確認屬實，籌備小組將刪除照片，情節重大者將刪除參賽者的資格。</li>
            <li>參賽者同意上傳照片後即無法再登入修改(1張/人)。</li>
            <li>投票者經同意投票後即無法再登入修改(10票/人)。</li>
            <li>P function投PH，K function投KFC，Share function 依據員編系統會自動導入。</li>
            <li>參賽者全權同意投票結果與籌辦小組決定，對於評審結果不得異議。</li>
            <li>經公布得獎之作品，得獎人不得要求取消得獎資格。</li>
            <li>本活動獎項屬於競技競賽及機會中獎之獎金或給與，得獎人應依所得稅法規定扣稅，獎項價值超過新臺幣 20,000 元以上，得獎者須按中獎價值預先扣繳 10% 機會中獎所得稅（不足 1 元部分，則四捨五入至元），並於領取獎品時繳交身份證影本正反面。無論扣繳與否，均需列入活動得獎人當年度之個人機會中獎所得，並由本公司開立扣繳憑單予活動得獎人，如不願意配合者，則視為自動放棄得獎資格。</li>
            <li>獎項以公佈於本網站上的資料為準，如遇不可歸責於籌辦小組之因素，籌辦小組得更換其他等值獎項。得獎者同意籌辦小組對所有領取或使用獎品之後果概無需負責。</li>
            <li>參賽者及參賽作品如有違反本活動規範、違法侵權或電腦合成照片疑慮，經查證屬實，籌辦小組將對參賽者依法進行求償，若發生前述行為，參賽者應配合籌辦小組進行調查（包括但不限於參賽作品提出作品來源、拍攝過程…等）供籌辦小組檢驗或及對外澄清，且若因此產生之費用（含律師費、訴訟費、對第三人的損害賠償等），由參賽者全權負擔，並自動放棄參賽資格者，同組參賽者(或非員工)亦同。</li>
            <li>免責聲明：本次參賽者(含同組非員工人員)，皆於決定參賽時，同意對於任何因參加本次競賽及競賽相關活動，及因接受、使用、誤用、或持有競賽所得獎勵，而衍生之任何權利主張、損失與傷害，均同意釋出、放棄、豁免JRG與其合作夥伴、附屬單位、子公司、廣告代理商、代理人、及其員工、經理人、董事和代表人所負之責任，並使之不因任何權利主張、損失與傷害而受有任何損害。且籌辦小組不為以下情形承擔責任：任何人為操作或檔案傳送時造成的錯誤、疏漏、干擾、資料刪除或遺失、瑕疵或延遲；線路故障；參賽作品或報名資料遭竊、遭毀損或遭到未經授權的存取；或者報名資料遭竄改。籌辦小組也不對任何電話網路或線路、電腦網路系統、伺服器與提供商、電腦設備及軟體的問題或技術故障情形負責，且不因任何因網路或網站技術或連線、人為操作失誤，或以上多個因素綜合而導致之電子郵件訊息延遲收發狀況負責，包括因參與本競賽所受、上傳或下載本競賽相關文件所生或與之有關而對參賽者本人與相關人之電腦配備所造成之損害。</li>
            <li>所有參賽者均視為已閱讀、理解並同意上述所有條款。如有未盡事宜，籌辦小組保留隨時解釋、變更及補充本參賽辦法、細則及獎項說明內容之權利，若因不可抗力之因素而無法進行本活動，籌辦小組有權決定終止、變更或暫停本活動，並保留活動修改正式規定內容權利。對於本活動如有任何疑問，請洽2024 Rainbow Month Taskforce team。</li>
        </ul>
        </div>
    );
}

export default ParticipationRules;
