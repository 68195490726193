import React from 'react';
import '../styles/styles.scss';
import closeIcon from '../assets/ic_pop_close.svg';
import success from '../assets/ic_success.svg'; 

const UploadSuccessPopup = ({ onClose }) => {
    const handleClose = () => {
        console.log("Close button clicked"); // 添加的 console.log 語句
        onClose();
    };

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup" onClick={e => e.stopPropagation()}>
                <div className='popup_header'>
                    <span className='t30'>操作已完成</span>
                    <button className="" onClick={handleClose}>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className='popup_status'>
                    <img src={success} alt="" />
                    <div className='popup_context'>上傳照片或活動投票已經完成，請靜待活動結果</div> 
                </div>
                <button className='btn-style3 t18' onClick={onClose}>確定</button>
            </div>
        </div>
    );
};


export default UploadSuccessPopup;
