import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/styles.scss';
import Back from './assets/ic_back_white.svg';
import PhotoOverlay from './PhotoOverlay'; // 导入 PhotoOverlay 组件
import SubmitCheck from './Popup/SubmitCheck'; // 导入 SubmitCheck 组件
import { DOMAIN } from './config';


const Vote = () => {
    const [isVoted, setIsVoted] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [contestantCount, setContestantCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false); // 管理提交按钮的禁用状态
    const [overlayPhoto, setOverlayPhoto] = useState(null); // 管理当前放大的照片
    const [showSubmitCheck, setShowSubmitCheck] = useState(false); // 控制 SubmitCheck 弹窗的显示
    const navigate = useNavigate();

    useEffect(() => {
        const checkVoteStatus = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                console.log('Access Token:', accessToken);

                if (!accessToken) {
                    // 如果没有 token，则返回首页
                    navigate('/');
                    return;
                }

                const response = await fetch(`${DOMAIN}/api/v1/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${accessToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('投票狀態:', data);
                    if (data.data.is_voted) { // 確保訪問 data.data.is_voted
                        setIsVoted(true);
                        navigate('/'); // 如果已投票，返回首頁
                    } else {
                        // 在這裡發起獲取照片的 API 請求，並傳遞 accessToken
                        fetchPhotos(accessToken);
                    }
                } else {
                    console.error('API 请求失败:', response.status);
                }
            } catch (error) {
                console.error('發生錯誤:', error);
            }
        };

        checkVoteStatus();
    }, [navigate]);

    // 獲取照片的函式，接受 accessToken 作為參數
    const fetchPhotos = async (accessToken) => {
        try {
            const response = await fetch(`${DOMAIN}/api/v1/vote/alllist`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${accessToken}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                console.log('照片資料:', result);
                // 確保資料是陣列
                if (result.data && Array.isArray(result.data.contestants)) {
                    setPhotos(result.data.contestants);
                    setContestantCount(result.data.count); // 設置參賽人數
                } else {
                    console.error('回傳資料格式錯誤:', result);
                }
            } else {
                console.error('API 请求失败:', response.status);
            }
        } catch (error) {
            console.error('發生錯誤:', error);
        }
    };

    const handleSelectPhoto = (photo) => {
        if (selectedPhotos.includes(photo)) {
            setSelectedPhotos(selectedPhotos.filter(p => p !== photo));
        } else if (selectedPhotos.length < 10) {
            setSelectedPhotos([...selectedPhotos, photo]);
        }
    };

    const handleVote = async () => {
        if (isSubmitting) return;

        if (selectedPhotos.length < 1 || selectedPhotos.length > 10) {
            alert('請選取1到10個名單');
            return;
        }

        setIsSubmitting(true); // 禁用按钮以防止多次提交

        const userIds = selectedPhotos.map(photo => photo.user_id);
        console.log('即將發送的 JSON:', JSON.stringify(userIds, null, 2)); // 打印 JSON 到控制台

        const accessToken = localStorage.getItem('accessToken');
        
        try {
            const response = await fetch(`${DOMAIN}/api/v1/vote/voting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${accessToken}`
                },
                body: JSON.stringify(userIds)
            });

            const result = await response.json();
            if (response.ok) {
                console.log('投票成功:', result);
                alert('投票成功！');
                navigate('/');
            } else {
                console.error('投票失敗:', result);
                alert(result.msg);
            }
        } catch (error) {
            console.error('發生錯誤:', error);
        } finally {
            setIsSubmitting(false); // 重新启用按钮
        }
    };

    const handleBackClick = () => {
        // 清除 token 并导航到首页
        localStorage.removeItem('accessToken');
        navigate('/');
    };

    const handlePhotoClick = (photoUrl) => {
        setOverlayPhoto(photoUrl);
        // 禁用页面滚动
        document.body.style.overflow = 'hidden';
    };

    const closeOverlay = () => {
        setOverlayPhoto(null);
        // 恢复页面滚动
        document.body.style.overflow = '';
    };

    const openSubmitCheck = () => {
        if (selectedPhotos.length < 1 || selectedPhotos.length > 10) {
            alert('請選取1到10個名單');
            return;
        }        
        setShowSubmitCheck(true);
    };

    const isSelected = (photo) => selectedPhotos.includes(photo);

    if (isVoted) {
        return <Link to="/">返回首頁</Link>;
    }

    return (
        <div className="container">
            <div className='vote_header'>
                <img src={Back} alt="返回" onClick={handleBackClick} />
                <span className='t30'>參與投票</span>
                <span className='t18'>總參賽人數：{contestantCount}</span>
            </div>
            <div className='vote_container'>
                {photos.map((photoData, index) => (
                    <div 
                        className={`vote_list ${isSelected(photoData) ? 'selected' : ''}`} 
                        key={index}
                        onClick={() => handleSelectPhoto(photoData)}
                    >
                        <img 
                            src={`${DOMAIN}${photoData.photo}`}  
                            alt={photoData.photo} 
                            onClick={() => handlePhotoClick(`${DOMAIN}${photoData.photo}`)}
                        />
                        <div className='vote_info'>
                            <span>{photoData.user.username}</span>
                            <button className='btn-style3 t18'>選取</button>
                        </div>
                    </div>
                ))}
            </div>
            <button className='btn-style2 t18 mb' onClick={openSubmitCheck} disabled={isSubmitting}>
                確定投票
            </button>
            {overlayPhoto && (
                <PhotoOverlay photo={overlayPhoto} onClose={closeOverlay} />
            )}
            {showSubmitCheck && (
                <SubmitCheck 
                    onClose={() => setShowSubmitCheck(false)} 
                    onConfirm={handleVote} 
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default Vote;
