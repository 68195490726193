import React, { useState, useEffect, useRef } from 'react';
import './styles/styles.scss';
import bannerImage from './assets/invision.png';
import LoginPopup from './Popup/LoginPopup';
import UploadPopup from './Popup/UploadPopup';
import UploadSuccessPopup from './Popup/UploadSuccessPopup';
import ActivityInfo from './ActivityInfo';
import ParticipationRules from './ParticipationRules';
import KFC from './assets/kfc.svg';
import Pizza from './assets/pizza.png';
import logo from './assets/logo.png';
import { DOMAIN } from './config';

const Home = () => {
    const [activeHint, setActiveHint] = useState(null);
    const buttonRefs = [useRef(null), useRef(null), useRef(null)];
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
    const [hasUploaded, setHasUploaded] = useState(false);
    const [kfcCount, setKfcCount] = useState(0);
    const [pizzaCount, setPizzaCount] = useState(0);
    const [topKfc, setTopKfc] = useState([]);
    const [topKfcDetails, setTopKfcDetails] = useState([]);
    const [topPizza, setTopPizza] = useState([]);
    const [topPizzaDetails, setTopPizzaDetails] = useState([]);
    const [clickedButton, setClickedButton] = useState(null);
    const [isUploadSubmitting, setIsUploadSubmitting] = useState(false);
    const [isVoteSubmitting, setIsVoteSubmitting] = useState(false);

    const handleButtonClick = (index) => {
        setActiveHint(index);
        setClickedButton(index);
        setTimeout(() => setClickedButton(null), 600);
    };

    const handleClickOutside = (event) => {
        if (buttonRefs.every(ref => ref.current && !ref.current.contains(event.target))) {
            setActiveHint(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        fetchContestants();

        // 設置一個定時器，每分鐘調用一次 fetchContestants
        const intervalId = setInterval(() => {
            fetchContestants();
        }, 60000); // 60000 毫秒 = 1 分鐘

        // 清理定時器以避免內存洩漏
        return () => clearInterval(intervalId);
    }, []);

    const fetchContestants = async () => {
        try {
            const response = await fetch(`${DOMAIN}/api/v1/vote/list`, {
                method: 'GET',
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Contestant data:', result);

                if (result.data) {
                    const kfcContestants = result.data.k || [];
                    const pizzaContestants = result.data.p || [];

                    // 取票数最高的参赛者
                    const topKfcContestants = kfcContestants.sort((a, b) => b.number_votes - a.number_votes);
                    const topPizzaContestants = pizzaContestants.sort((a, b) => b.number_votes - a.number_votes);

                    // 设置总票数和第一名的信息
                    setKfcCount(topKfcContestants[0] ? topKfcContestants[0].number_votes : 0);
                    setPizzaCount(topPizzaContestants[0] ? topPizzaContestants[0].number_votes : 0);

                    // 设置第一名
                    setTopKfc(topKfcContestants.slice(0, 1));
                    setTopPizza(topPizzaContestants.slice(0, 1));

                    // 设置第2到第10名
                    setTopKfcDetails(topKfcContestants.slice(1, 10));
                    setTopPizzaDetails(topPizzaContestants.slice(1, 10));
                } else {
                    console.error('Unexpected data format:', result);
                }
            } else if (response.status === 401) {
                console.error('未授權，请重新登录');
                setIsLoggedIn(false);
                localStorage.removeItem('accessToken');
                setIsLoginPopupOpen(true);
            } else {
                console.error('API 请求失败:', response.status);
            }
        } catch (error) {
            console.error('發生錯誤:', error);
        }
    };

    const handleLoginButtonClick = () => {
        setIsLoginPopupOpen(true);
    };

    const handleLoginSuccess = (responseData) => {
        setIsLoggedIn(true);
        setIsLoginPopupOpen(false);
        const accessToken = responseData.data.token;
        localStorage.setItem('accessToken', accessToken);
        console.log('Login successful, token saved:', accessToken);
    };

    const handleCloseLoginPopup = () => {
        setIsLoginPopupOpen(false);
    };

    const handleUploadButtonClick = async () => {
        if (isUploadSubmitting) return;
    
        // 獲取當前日期
        const currentDate = new Date();
        
        // 定義上傳開始和結束日期
        const uploadStartDate = new Date('2024-06-17T00:00:00'); // 開始日期
        const uploadEndDate = new Date('2024-09-15T23:59:59'); // 結束日期
    
        // 檢查當前日期是否在上傳範圍內
        if (currentDate < uploadStartDate || currentDate > uploadEndDate) {
            alert('投票時間為2024年6月17日~2024年9月15日');
            return; // 終止函數執行
        }
    
        setIsUploadSubmitting(true);
    
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log('Access Token:', accessToken);
    
            const response = await fetch(`${DOMAIN}/api/v1/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${accessToken}`
                }
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('上傳狀態:', data.msg);
                if (data.data && data.data.is_partake) {
                    setHasUploaded(true);
                } else {
                    setIsUploadPopupOpen(true);
                }
            } else if (response.status === 401) {
                console.error('未授權，請重新登入');
                setIsLoggedIn(false);
                localStorage.removeItem('accessToken');
                setIsLoginPopupOpen(true);
            } else {
                console.error('API 請求失敗:', response.status);
            }
        } catch (error) {
            console.error('發生錯誤:', error);
        } finally {
            setIsUploadSubmitting(false);
        }
    };
    

    const handleVoteButtonClick = async () => {
        if (isVoteSubmitting) return;
    
        // 獲取當前日期
        const currentDate = new Date();
        
        // 定義投票開始和結束日期
        const voteStartDate = new Date('2024-09-23T00:00:00'); // 開始日期
        const voteEndDate = new Date('2024-10-11T23:59:59'); // 結束日期
    
        // 檢查當前日期是否在投票範圍內
        if (currentDate < voteStartDate || currentDate > voteEndDate) {
            alert('投票時間為2024年9月23日~2024年10月11日');
            return; // 終止函數執行
        }
    
        setIsVoteSubmitting(true);
    
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log('Access Token:', accessToken);
    
            const response = await fetch(`${DOMAIN}/api/v1/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${accessToken}`
                }
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('投票狀態:', data);
                if (data.data.is_voted) {
                    setHasUploaded(true);
                } else {
                    window.location.href = '/vote';
                }
            } else {
                console.error('API 請求失敗:', response.status);
            }
        } catch (error) {
            console.error('發生錯誤:', error);
        } finally {
            setIsVoteSubmitting(false);
        }
    };
    

    return (
        <div className='main'>
            <div className="banner">
                <img src={bannerImage} alt="Banner" />
                <div className='box1'>
                    <div className='rol'>
                        <button className='btn-hidden'
                            onClick={() => handleButtonClick(0)}
                            ref={buttonRefs[0]}>
                            <img className={`btn_logo ${clickedButton === 0 ? 'clicked' : ''}`} src={logo} alt="KFC" />
                        </button>
                        {activeHint === 0 && <div className='hint'>6/17~9/15一同分享你與彩虹驕傲的觸碰瞬間！</div>}
                    </div>
                    <div className='rol2'>
                        <button className='btn-hidden'
                            onClick={() => handleButtonClick(1)}
                            ref={buttonRefs[1]}>
                            <img className={`btn_kfc ${clickedButton === 1 ? 'clicked' : ''}`} src={KFC} alt="KFC" />
                        </button>
                        {activeHint === 1 && <div className='hint'>與愛相遇，交織我的彩虹驕傲！</div>}
                        <button className='btn-hidden'
                            onClick={() => handleButtonClick(2)}
                            ref={buttonRefs[2]}>
                            <img className={`btn_pizza ${clickedButton === 2 ? 'clicked' : ''}`} src={Pizza} alt="Pizza" />
                        </button>
                        {activeHint === 2 && <div className='hint'>在彩虹的色彩中，讓我們共同慶祝愛的共鳴！</div>}
                    </div>
                </div>
            </div>
            <div className='container'>
            <div className='btn-group'>
                    {!isLoggedIn ? (
                        <button className='btn-style2 t18' onClick={handleLoginButtonClick}>
                            立即登入
                        </button>
                    ) : (
                        <>
                            <button className='btn-style1 t18' onClick={handleUploadButtonClick}
                                disabled={isUploadSubmitting}>
                                {isUploadSubmitting ? '正在提交...' : '上傳照片'}
                            </button>
                            <button className='btn-style2 t18' onClick={handleVoteButtonClick}
                                disabled={isVoteSubmitting}>
                                {isVoteSubmitting ? '正在提交...' : '參與投票'}
                            </button>
                        </>
                    )}
                </div>
                <LoginPopup isOpen={isLoginPopupOpen} onClose={handleCloseLoginPopup} onLoginSuccess={handleLoginSuccess} />
                <UploadPopup isOpen={isUploadPopupOpen} onClose={() => setIsUploadPopupOpen(false)} parentToken={localStorage.getItem('accessToken')} />
                {hasUploaded && (
                    <UploadSuccessPopup onClose={() => setHasUploaded(false)} />
                )}
                <span className='t32'>投票排名</span>
                <div className='vote'>
                    <div className='vote_frame'>
                        <div className='vote_kfc'>
                            {topKfc.length > 0 ? (
                                <>
                                    <img src={`${DOMAIN}${topKfc[0].photo}`} alt="Top KFC Contestant" />
                                    <div className='vote_text'>
                                        <span className='t30'>{topKfc[0].user.username}</span>
                                        <span className='kfc_amount'>{topKfc[0].number_votes}票</span>
                                    </div>
                                </>
                            ) : (
                                <div className='vote_text'>
                                    <span className='t30'>KFC 投稿熱烈徵募中(6/17-9/15)</span>
                                </div>
                            )}
                        </div>
                        <div className='vote_detail'>
                            {topKfcDetails.map((contestant, index) => (
                                <div className='vote_block' key={index}>
                                    <div className='vote_img'>
                                        <img src={`${DOMAIN}${contestant.photo}`} alt={`Contestant ${index + 2}`} />
                                    </div>
                                    <span className='t18 red'>{contestant.user.username} / {contestant.number_votes}票</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='vote_frame'>
                        <div className='vote_pizza'>
                            {topPizza.length > 0 ? (
                                <>
                                    <img src={`${DOMAIN}${topPizza[0].photo}`} alt="Top Pizza Contestant" />
                                    <div className='vote_text'>
                                        <span className='t30'>{topPizza[0].user.username}</span>
                                        <span className='pizza_amount'>{topPizza[0].number_votes}票</span>
                                    </div>
                                </>
                            ) : (
                                <div className='vote_text'>
                                    <span className='t30'>Pizza Hut 投稿熱烈徵募中(6/17-9/15)</span>
                                </div>
                            )}
                        </div>
                        <div className='vote_detail'>
                            {topPizzaDetails.map((contestant, index) => (
                                <div className='vote_block' key={index}>
                                    <div className='vote_img'>
                                        <img src={`${DOMAIN}${contestant.photo}`} alt={`Contestant ${index + 2}`} />
                                    </div>
                                    <span className='t18 blue'>{contestant.user.username} / {contestant.number_votes}票</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <span className='t32'>活動介紹</span>
                <ActivityInfo />
                <span className='t32'>參賽規範</span>
                <ParticipationRules />
                {/* <div className='btn-group'>
                    {!isLoggedIn ? (
                        <button className='btn-style2 t18' onClick={handleLoginButtonClick}>
                            立即登入
                        </button>
                    ) : (
                        <>
                            <button className='btn-style1 t18' onClick={handleUploadButtonClick}
                                disabled={isUploadSubmitting}>
                                {isUploadSubmitting ? '正在提交...' : '上傳照片'}
                            </button>
                            <button className='btn-style2 t18' onClick={handleVoteButtonClick}
                                disabled={isVoteSubmitting}>
                                {isVoteSubmitting ? '正在提交...' : '參與投票'}
                            </button>
                        </>
                    )}
                </div>
                <LoginPopup isOpen={isLoginPopupOpen} onClose={handleCloseLoginPopup} onLoginSuccess={handleLoginSuccess} />
                <UploadPopup isOpen={isUploadPopupOpen} onClose={() => setIsUploadPopupOpen(false)} parentToken={localStorage.getItem('accessToken')} />
                {hasUploaded && (
                    <UploadSuccessPopup onClose={() => setHasUploaded(false)} />
                )} */}
            </div>
        </div>
    );
};

export default Home;
