import React from 'react';
import './styles/photoOverlay.scss';

const PhotoOverlay = ({ photo, onClose }) => {
    return (
        <div className="photo-overlay" onClick={onClose}>
            <div className="photo-overlay-content" onClick={e => e.stopPropagation()}>
                <img src={photo} alt="Photo" />
            </div>
        </div>
    );
};

export default PhotoOverlay;
