import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.scss';
import defaultPhoto from '../assets/img_default.svg'; 
import closeIcon from '../assets/ic_pop_close.svg';
import example1 from '../assets/example1.jpg';
import example2 from '../assets/example2.jpg';    
import SubmitCheck from './SubmitCheck'; // 导入 SubmitCheck 组件 
import { DOMAIN } from '../config';

const UploadPopup = ({ isOpen, onClose }) => {
    const [selectedPhoto, setSelectedPhoto] = useState(defaultPhoto);
    const [file, setFile] = useState(null); // 保存选择的文件
    const [username, setUsername] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // 管理提交按钮的禁用状态
    const [showSubmitCheck, setShowSubmitCheck] = useState(false); // 控制 SubmitCheck 弹窗的显示
    const navigate = useNavigate(); // 用于导航

    useEffect(() => {
        if (isOpen) {
            const fetchUsername = async () => {
                try {
                    const accessToken = localStorage.getItem('accessToken');
                    console.log('Access Token:', accessToken);

                    const response = await fetch(`${DOMAIN}/api/v1/user`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${accessToken}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setUsername(data.data.username);
                    } else {
                        console.error('API 请求失败:', response.status);
                    }
                } catch (error) {
                    console.error('發生錯誤:', error);
                }
            };

            fetchUsername();
        }
    }, [isOpen]);

    const handlePhotoChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const photoURL = URL.createObjectURL(selectedFile);
            setSelectedPhoto(photoURL);
            setFile(selectedFile); // 设置文件
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const handleSubmit = async () => {
        if (!file) {
            alert('請選擇一張照片');
            return;
        }
        if (isSubmitting) return;
    
        setIsSubmitting(true); // 禁用按钮以防止多次提交
    
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log('Access Token:', accessToken);
    
            const formData = new FormData();
            formData.append('photo', file); // 将文件添加到 FormData 中的 'photo' 键下
    
            const apiUrl = `${DOMAIN}/api/v1/user/partake`;
            
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                body: formData
            });
    
            if (response.ok) {
                alert('上傳成功');
                resetStateAndClose();
            } else {
                alert('照片不符合規定，請確定照片格式及檔案大小');
                resetStateAndClose(); // 上传失败时重置状态并关闭弹窗
            }
        } catch (error) {
            console.error('Error uploading photo:', error);
            alert('照片不符合規定，請確定照片格式及檔案大小');
            resetStateAndClose(); // 上传过程中发生错误时重置状态并关闭弹窗
        } finally {
            setIsSubmitting(false); // 重新启用按钮
        }
    };

    const resetStateAndClose = () => {
        setSelectedPhoto(defaultPhoto);
        setFile(null);
        setShowSubmitCheck(false); // 确保重置 showSubmitCheck 的状态
        onClose();
    };

    const openSubmitCheck = (event) => {
        event.preventDefault();
        if (!file) {
            alert('請選擇一張照片');
            return;
        }
        setShowSubmitCheck(true);
    };

    if (!isOpen) return null;
    const isDefaultPhoto = selectedPhoto === defaultPhoto;

    return (
        <div className="popup-overlay" onClick={resetStateAndClose}>
            <div className="popup" onClick={e => e.stopPropagation()}>
                <div className='popup_header'>
                    <span className='t30'>上傳照片</span>
                    <button className="" onClick={resetStateAndClose}>
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>
                <div className='popup_context'>上傳後將無法修改，請注意照片是否正確</div>
                
                <form onSubmit={openSubmitCheck}>
                    <div className='photo_example'>
                        <div className='photo_group'>
                            <div className='photo'>
                                <span className='photo_title'>照片範例1</span>
                                <img src={example1} alt="Example 1" />
                            </div>
                            <div className='photo'>
                                <span className='photo_title'>照片範例2</span>
                                <img src={example2} alt="Example 2" />
                            </div>
                        </div>
                    </div>
                    <div className='upload_field'>
                        <span>ID {username}</span>
                        <img src={selectedPhoto} alt="Selected" onClick={triggerFileInput} className={isDefaultPhoto ? "default-photo" : "uploaded-photo"}/>
                        <button type="button" className='btn-outline' onClick={triggerFileInput}>選擇照片</button>
                        <input
                            id="fileInput"
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handlePhotoChange}
                        />
                    </div>
                    <button className='btn-style3 t18' type="submit">
                        確定上傳
                    </button>
                </form>
            </div>
            {showSubmitCheck && (
                <SubmitCheck 
                    onClose={() => setShowSubmitCheck(false)} 
                    onConfirm={handleSubmit} 
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default UploadPopup;
